<template>
  <div class="Feedback">
    <Header/>
    <div class="FeedbackWrap">
      <div class="title">意见反馈</div>
      <div class="title2" style="margin-bottom: 20px">反馈类型</div>
      <div class="typeList">
        <div class="item" @click="select('COMPLAIN')" :class="{active:type==='COMPLAIN'}">功能建议</div>
        <div class="item" @click="select('FUNCTION')" :class="{active:type==='FUNCTION'}">投诉意见</div>
        <div class="item" @click="select('OTHER')" :class="{active:type==='OTHER'}">其他反馈</div>
      </div>
      <div class="title2" style="margin-bottom: 20px">反馈描述</div>
      <el-form :model="form" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="content">
          <el-input
              type="textarea"
              :rows="2"
              maxlength="150"
              show-word-limit
              placeholder="请详细说明，以便于我们解决问题,您最多可以填写150字。"
              v-model="form.content">
          </el-input>
        </el-form-item>
        <div class="title" style="margin-top: 20px;margin-bottom: 14px">上传图片</div>
        <!--        <el-upload-->
        <!--            action="aaa"-->
        <!--            class="avatar-uploader"-->
        <!--            list-type="picture-card"-->
        <!--            multiple-->
        <!--            :show-file-list="false"-->
        <!--            :http-request="uploadHttpRequest"-->
        <!--            :file-list="fileList"-->

        <!--            accept=".jpg, .bmp, .png, .gif"-->
        <!--            :before-upload="beforeAvatarUpload"-->
        <!--        >-->
        <!--          <img v-if="form.imgs.length!==0" :src="form.imgs[0].url"/>-->
        <!--          <div v-else class="uploader_icon">-->
        <!--            <img src="https://static.dasdt.cn/images/photo.png">-->
        <!--            <span>上传图片</span>-->
        <!--          </div>-->
        <!--        </el-upload>-->
        <el-upload
            ref="upload"
            class="avatar-uploader"
            action="https://rc-doc.51shanjian.cn/upload/feedback/images"
            accept=".jpg, .bmp, .png, .gif"
            :http-request="uploadFiles"
            multiple
            :before-upload="beforeAvatarUpload"
            :on-success="handleSuccess"
            :on-change="handleChange"
            :before-remove="handleremove"
            :file-list="fileList"
            list-type="picture-card"
            :limit="3"
        >
          <div class="uploader_icon">
            <img src="https://static.dasdt.cn/images/photo.png">
            <span>上传图片</span>
          </div>
        </el-upload>
        <div class="font_1" style="margin-top: 10px">支持JPG、JPEG、PNG格式(选填)</div>
        <div class="font_1" style="margin-top: 4px">最多可上传3张</div>
        <div class="title" style="margin-top: 41px;margin-bottom: 14px">联系方式</div>
        <el-form-item prop="contact">
          <el-input
              type="textarea"
              :rows="1"
              maxlength="20"
              placeholder="请输入您的手机号或邮箱（以便我们及时反馈）"
              v-model="form.contact">
          </el-input>
        </el-form-item>
      </el-form>


      <div class="footerWrap">
        <div class="left" @click="back">取消</div>
        <div class="right" @click="addFeedback('ruleForm')">提交反馈</div>
      </div>
      <div></div>
    </div>
    <Footer/>
    <floatRight/>
  </div>
</template>
<script>
import Header from "@/components/headers/index.vue";
import floatRight from "@/components/floatRight/vue.vue";
import Footer from "@/components/footer/index.vue";
import {addFeedback} from "@/api/position";
import {feedbackImages} from "@/api/currency";

export default {
  components: {Footer, floatRight, Header},
  data() {
    return {
      imageUrl: '',
      form: {
        contact: '',
        content: '',
        type: 'FUNCTION',
        imgs: []
      },
      fileList: [],
      rules: {
        content: [
          {required: true, message: '请输入反馈内容', trigger: 'blur'},
          {max: 150, message: '请输入150字', trigger: 'blur'}
        ],
        contact: [
          {required: true, message: '请输入联系方式', trigger: 'blur'},
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('请输入手机号或邮箱'));
              }
              const regex = /^(1[3-9]\d{9}$)|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
              if (!regex.test(value)) {
                return callback(new Error('请输入有效的手机号或邮箱'));
              }
              callback();
            },
            trigger: 'blur'
          }
        ]
      },
      type: 'COMPLAIN'
    }
  },
  created() {
  },
  methods: {
    select(el) {
      this.type = el
    },
    async addFeedback(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.form.type = this.type
          const {data} = await addFeedback(this.form)
          console.log(data)
          this.$message({
            message: '提交成功',
            type: 'success'
          })
          await this.$router.push('/')
        }
      })
    },
    back() {
      this.$router.go(-1)
    },
    async beforeAvatarUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["jpg", "png", "bmp", "gif"];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          message: "文件必须为 jpg,png,bmp,gif 类型",
          type: "error",
        });
        return false;
      }

      if (file.size / 1024 / 1024 > 20) {
        this.$message({
          message: "上传文件大小不得大于20MB!",
          type: "error",
        });
        return false;
      }
      return true
    },
    // 自定义上传请求方法
    async uploadFiles(file) {
      const formData = new FormData();
      formData.append('files', file.file);

      const {data} = await feedbackImages(formData)
      this.handleSuccess(data[0], file);
      const prom = new Promise((resolve, reject) => {
        console.log(resolve, reject)
      })
      prom.abort = () => {
      }
      return prom

    },
    // 上传成功回调
    handleSuccess(responseData) {
      const updatedFile = {
        name: responseData.name,
        url: responseData.url,
        uname: responseData.uname
      };

      this.form.imgs.push(updatedFile);
      console.log(222222, this.form.imgs)
    },
    handleChange(fileList) {
      this.fileList.push(fileList)
      console.log(fileList)
    },
    handleremove(file, fileList) {
      fileList.forEach((item, index) => {
        if (item.name === file.name) {
          this.fileList.splice(this.fileList.indexOf(item), 1)
          this.form.imgs.splice(index, 1)
        }
      })
      console.log(this.fileList)
      console.log(this.form.imgs)
    }
  },


}
</script>
<style lang="scss">
.Feedback {
  background: #F5F5F6;
  width: 100%;
  overflow: hidden;
  min-height: calc(100vh);
  .el-textarea .el-input__count{
    background: #F7F8FA;
  }

  .FeedbackWrap {
    position: relative;
    z-index: 10;
    margin: 72px auto 24px auto;
    width: 1000px;
    min-height: calc(100vh - 157px);
    background: #fff;
    border-radius: 8px;
    padding: 22px;
    .typeList{
      display: flex;
      align-items: center;
      gap: 14px;
      .active{
        background: #3A76FF !important;
        color: #fff !important;
      }
      .item{
        cursor: pointer;
        background: #F7F8FA;
        width: 88px;
        height: 38px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #888888;
        font-size: 14px;
      }
    }

    .footerWrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 20px;


      .left {
        width: 170px;
        height: 44px;
        border-radius: 100px;
        border: 1px solid #ADADAD;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }

      .right {
        cursor: pointer;
        width: 170px;
        height: 44px;
        border-radius: 100px;
        background: #3A76FF;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .font_1 {
      color: #888888;
      font-size: 14px;
      line-height: 22px;
    }
    .el-upload-list--picture-card .el-upload-list__item{
      margin: 0 8px 0 0;
      width: 76px;
      height: 76px;
    }
    .el-upload--picture-card{
      width: 76px;
      height: 76px;
      line-height: 28px;
    }
    .avatar-uploader{
      display: flex;
      align-items: center;
    }

    .avatar-uploader .el-upload {
      border: 1px solid #3076FF;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      border-radius: 6.78px;
      width: 76px;
      height: 76px;
      display: flex;
      justify-content: center;
    }

    .uploader_icon {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      img {
        width: 30px;
        height: 30px;
      }

      span {
        color: #3A76FF;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 76px;
      height: 76px;
      line-height: 76px;
      text-align: center;
    }

    .avatar {
      width: 76px;
      height: 76px;
      display: block;
    }

    .el-textarea__inner {
      border: none !important;
      background: #F7F8FA !important;
      border-radius: 6px !important;
    }

    .title2 {
      margin-top: 20px;
      color: #333333;
      font-size: 18px;
      font-weight: 500;
    }

    .title {
      color: #000000;
      font-size: 24px;
      font-weight: 500;
    }
  }
}
</style>
